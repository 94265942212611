 
#outer{
  margin: auto;
  /* margin-top: 15%; */
  /* background: #44b9fc; */
  height: 100px;
  width: 100px;
  border-radius: 15px;
}
#inner{
    background: #fff;
    height: 48px;
    width: 48px;
    margin:auto;
    position: relative;
    top:6px;
    border-radius: 50%;
  }
#middle{
  height: 60px;
  width: 60px;
  margin:auto;
  position: relative;
  top:20px;
  border-radius: 50%;
  transform-origin: 50% 50%;
  animation-timing-function: ease;
  background-image: linear-gradient(150deg, transparent 50%, #124414 50%),linear-gradient(90deg, #12441e 50%, #00000063 50%);
   animation: jjj 1200ms infinite linear;

}

@keyframes jjj {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
 
.bg-infooo  .footer-col-text {
    border-top: 1px solid #ffffff;
}
.bg-infooo .footer-col-text h5 {
    text-transform: capitalize;
    color: #fff;
    font-size: 20px;
    margin-top: 5px;
    font-weight: 300 !important;
    font-family: 'Muli', sans-serif;
    margin-bottom: 15px;
}
.bg-infooo .footer-col-text p {
    font-size: 16px;
    font-weight: 400;
    color: #f1eee9;
    line-height: 26px;
    margin: 0;
    font-family: 'Muli', sans-serif;
}
.bg-infooo .footer-col-text .footer-link {
    display: block;
    color: #ffffff;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    font-family: 'Roboto', sans-serif;
}
.bg-infooo .footer-col-text .footer-btn {
    display: inline-block;
    color: #ffffff;
    font-size: 14px;
    border: 1px solid #ffffff;
    text-decoration: none;
    padding: 12px 18px;
    margin-top: 20px;
    transition: .5s;
    margin-bottom: 40px;
    font-family: 'Roboto', sans-serif;
}
.bg-infooo .footer-col-text .footer-btn:hover {
    background: #fff;
    color: #153f41;
}
.bg-infooo .footer-col-text .footer-link:hover {
    text-decoration: underline;
}
.bg-infooo .footer-col-text .footer-link:after {
    position: absolute;
    content: '\f082';
    top: 0;
    font-family: fontAwesome;
    left: 0;
    font-weight: 400;
    font-size: 16px;
}
.bg-infooo .footer-col-text .fb-icon {
    padding-left: 20px;
}
.bg-infooo .footer-bottom-text p {
    font-size: 13px;
    padding-top: 30px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}
.bg-infooo .footer-bottom-text p a {
    color: white;
}

@media screen and (max-width:991px) and (min-width:768px) {
    .bg-infooo.p-5 .row .col-md-4 {
        width: 100%;
        margin-bottom: 40px;
    }
    .bg-infooo .footer-col-text .footer-btn {
        margin-bottom: 0;
    }
}
@media screen and (max-width:767px) {
    .bg-infooo .footer-col-text {
        margin-bottom: 30px;
    }
    .bg-infooo .footer-bottom-text p {
        line-height: 25px;
    }
    .bg-infooo .footer-col-text .footer-btn {
        margin-bottom: 0;
    }
    .bg-infooo.p-5 {
        padding: 45px 25px !important;
    }
}
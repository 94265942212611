.header {
    box-shadow: 0 0 10px #333;
    display: inline-block;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 22;
    background: white;
    /* padding: 15px 0; */
}
.margin-top {
    margin-top: 90px;
}
.header .header-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.header-inner .contact {
    width: 35%;
}
.header-inner .contact ul, .header-inner .menu .navigation ul {
    display: flex;
    align-items: center;
    column-gap: 20px;
    list-style: none;
    margin-bottom: 0;
    justify-content: center;
    flex-wrap: wrap;
}
.header-inner .menu {
    width: 50%;
}
.header-inner .menu .navigation ul {
    justify-content: flex-end;
}
.header-inner .logo {
    width: 14%;
    height: 78px;
}
.header-inner .logo img {
    width: auto;
    height: 100%;
    object-fit: contain;
}
.header-inner .contact ul a, .header-inner .menu .navigation ul a {
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 32px;
    color: #444444;
}
@media screen and (max-width:1279px) and (min-width:1024px) {
    .header-inner .contact ul {
        flex-wrap: nowrap;
    }
    .header-inner .contact ul a, .header-inner .menu .navigation ul a {
        font-size: 12px;
    }
    
}
@media screen and (max-width:1023px) and (min-width:992px) {
    .header-inner .contact ul {
        flex-wrap: nowrap;
    }
    .header-inner .contact ul a, .header-inner .menu .navigation ul a {
        font-size: 12px;
    }
}
@media screen and (max-width:991px) and (min-width:768px) {
    .header-inner .logo {
        width: 50%;
    }
    .header-inner .contact {
        width: 100%;
        /* display: none; */
        position: absolute;
        z-index: 99999;
        color: #fff;
        bottom: 0;
        top: 353px;
    }
/* .con_tel{
    display: block !important;
} */
.con_tel_slide {
    left: 0 !important;
}

.header-inner .contact .con_tel {
    left: 100%;
    position: absolute;
    transition: 0.4s;
}
.header-inner .contact ul a {
    color: #fff;
}
.header-inner .contact ul li {
    width: 100%;
}
.header-inner .contact ul {
    padding-left: 0;
}
    .header-inner .menu {
        width: 100%;
    }
    .navigation li {
        width: 100%;
        display: block;
    }
    .header-inner .menu .navigation ul {
        padding-left: 0;
        padding-top: 80px;
    }
    .navigation {
        right: 0;
        transition: 0.4s;
        height: 100vh;
        width: 0;
        top: 0;
        position: absolute;
        overflow: hidden;
    }
    .slider_menu {
        width: 100%;
        background: #444444;
        transition: 0.4s;
        height: 100vh;
        /* opacity: 1; */
        overflow: hidden;
        position: absolute;
        margin-right: 0;
    }
    .toggle_menu {
        position: absolute;
        right: 0;
        z-index: 9999;
        /* background: #fff; */
        width: 100%;
        height: 79px;
        top: 0;
        text-align: right;
        padding-top: 18px;
        padding-right: 15px;
        border-bottom: 1px solid #fff;
    }
    .toggle_menu span {
        width: 30px;
        height: 2px;
        background: #444444;
        display: inline-block;
        position: relative;
    }
    .toggle_menu span:after, .toggle_menu span:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 100%;
        height: 2px;
        background: #000;
        transition: 1s;
    }
    .toggle_menu span:before {
        top: -10px;
    }
    .header-inner .menu .navigation ul a {
        color: #E8E0CC;
    }
    /* date 05-01-2022 */
    .toggle_menu_co span {
        background: transparent;
        transition: 1s;
        height: 0;
    }
    .toggle_menu_co span::before {
        transform: rotate(136deg);
        top: 0;
        background: #fff;
        transition: 1s;
    }
    .toggle_menu_co span::after {
        transform: rotate(45deg);
        top: 0;
        background: #fff;
        transition: 1s;
    }
    .logo_co {
        z-index: 9;
        filter:invert(1)sepia(1);
    }
    .toggle_menu_co {
        border-bottom: 2px solid #fff;
    }
    .header-inner .logo img {
        width: 125px;
    }
    .navigation li {
        border-bottom: 2px solid #E8E0CC;
        padding: 5px 15px;
    }
}
@media screen and (max-width:767px) {
    .header-inner .logo {
        width: 50%;
    }
    .header-inner .contact {
        width: 100%;
        /* display: none; */
        position: absolute;
        z-index: 99999;
        color: #fff;
        bottom: 0;
        top: 353px;
    }
/* .con_tel{
    display: block !important;
} */
.con_tel_slide {
    left: 0 !important;
}

.header-inner .contact .con_tel {
    left: 100%;
    position: absolute;
    transition: 0.4s;
}
.header-inner .contact ul a {
    color: #fff;
}
.header-inner .contact ul li {
    width: 100%;
}
.header-inner .contact ul {
    padding-left: 0;
}
    .header-inner .menu {
        width: 100%;
    }
    .navigation li {
        width: 100%;
        display: block;
    }
    .header-inner .menu .navigation ul {
        padding-left: 0;
        padding-top: 80px;
    }
    .navigation {
        right: 0;
        transition: 0.4s;
        height: 100vh;
        width: 0;
        top: 0;
        position: absolute;
        overflow: hidden;
    }
    .slider_menu {
        width: 100%;
        background: #444444;
        transition: 0.4s;
        height: 100vh;
        /* opacity: 1; */
        overflow: hidden;
        position: absolute;
        margin-right: 0;
    }
    .toggle_menu {
        position: absolute;
        right: 0;
        z-index: 9999;
        /* background: #fff; */
        width: 100%;
        height: 79px;
        top: 0;
        text-align: right;
        padding-top: 18px;
        padding-right: 15px;
        border-bottom: 1px solid #fff;
    }
    .toggle_menu span {
        width: 30px;
        height: 2px;
        background: #444444;
        display: inline-block;
        position: relative;
    }
    .toggle_menu span:after, .toggle_menu span:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 100%;
        height: 2px;
        background: #000;
        transition: 1s;
    }
    .toggle_menu span:before {
        top: -10px;
    }
    .header-inner .menu .navigation ul a {
        color: #E8E0CC;
    }
    /* date 05-01-2022 */
    .toggle_menu_co span {
        background: transparent;
        transition: 1s;
        height: 0;
    }
    .toggle_menu_co span::before {
        transform: rotate(136deg);
        top: 0;
        background: #fff;
        transition: 1s;
    }
    .toggle_menu_co span::after {
        transform: rotate(45deg);
        top: 0;
        background: #fff;
        transition: 1s;
    }
    .logo_co {
        z-index: 9;
        filter:invert(1)sepia(1);
    }
    .toggle_menu_co {
        border-bottom: 2px solid #fff;
    }
    .header-inner .logo img {
        width: 125px;
    }
    .navigation li {
        border-bottom: 2px solid #E8E0CC;
        padding: 5px 15px;
    }
}
.navigation li.active a{
    border-bottom: 4px solid #A4946B;
}

.header-inner .contact ul a i {
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
}
.header-inner .contact ul a .fa-mobile {
    font-size: 20px;
}
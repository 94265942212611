@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
body{
    font-family: 'Roboto';
}
.select-modal-slider {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
}
.select-modal-slider .slider-text {
    margin-left: 30px;
    width: calc(33.333% - 30px);
}
  .title{
    text-transform: uppercase;
    font-weight: 700;
    background: #444444;
    color: #FFFFFF ;
    margin-left: -30px;
    padding-bottom: 8px;
    padding-top: 8px;
    padding-left: 30px;
    font-size: 30px;
    clip-path: polygon(0% 0%, 75% 0%, 100% 1%, 85% 100%, 0% 100%);
    display: inline-block;
    padding-right: 100px;
}
.right-box h2 {
    background: #444;
    color: #FFFFFF;
    padding: 10px;
}
.slider-text .text-inner .value {
    display: block;
    border-top: 2px solid #12441E ;
    padding: 16px 0 30px;
    font-size: 14px;
    font-weight: 400;
    color: #444444;
    line-height: 26px;
}
.select_mod-page {
    padding: 20px 0;
}
.slider-text .text-inner .value strong {
    display: block;
    font-size: 36px;
    color: #444444 ;
    font-weight: 700;
    line-height: 30px;
}
.select-modal-slider .img-slider .carousel-control-next,
.select-modal-slider .img-slider .carousel-control-prev{
    color: #fff;
    opacity: 9;
}
.select-modal-slider .img-slider .sl-search-icon {
    position: absolute;
    z-index: 2;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background: #E8E0CC 54;
    text-align: center;
    padding-top: 7px;
    text-decoration: none;
    opacity: 0;
    transition: 1s;
}
.select-modal-slider .img-slider .carousel-indicators,
.flor_cust .carousel .carousel-indicators {
    display: none;
}
.select-modal-slider .img-slider .sl-search-icon:before {
    content: "\f002";
    top: 0;
    left: 0;
    font-size: 40px;
    font-family: 'FontAwesome';
    font-weight: 400;
    color: #fff;
}
.select-modal-slider .img-slider .carousel-control-prev-icon:before {
    position: absolute;
    content: "\f104";
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    font-size: 45px;
    font-weight: 400;
    z-index: 9;
}
.select-modal-slider .img-slider .carousel-control-next-icon::after{
    position: absolute;
    content: "\f105";
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    font-size: 45px;
    font-weight: 400;
    z-index: 9;
}
.select-modal-slider .img-slider .carousel-control-prev-icon,
.select-modal-slider .img-slider .carousel-control-next-icon{
    background-image: unset;
}
.img-slider:hover .sl-search-icon {
    opacity: 1;
}
.our-partenr {
    background: #000;
    padding: 50px 0;
}
.our-partenr h2 {
    color: #444444 ;
    text-align: center;
    margin-bottom: 40px;
}
.our-partenr .logo-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    column-gap: 20px;
    flex-wrap: wrap;
}
.connect-section{
    padding: 50px 0;
    text-align: center;
}
.news-letter{
    margin-bottom: 50px;
    text-align: center;
}
.connect-section .social-media h2, .news-letter .form h2 {
    color: #12441E;
    font-size: 32px;
    margin-bottom: 30px;
}
.news-letter .main-form {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 650px;
    margin: 0 auto;
}
.news-letter .main-form input {
    width: 100%;
    margin-top: 15px;
    height: 50px;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
}
.our-partenr .logo-gallery div {
    width: calc(20% - 20px);
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
.connect-section .social-link {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 60px;
}
.connect-section .social-link a, .news-letter a {
    text-decoration: none;
    color: #000000;
    font-size: 16px;
    transition: 0.5s;
    font-weight: 400;
}
.connect-section .social-link a i {
    display: inline-block;
    padding-right: 10px;
}
.connect-section .social-link a:hover, .news-letter a:hover,
.news-letter .main-form .submit:hover,
.graph-map .text-inner table .classic-button button:hover {
    opacity: 0.8;
}
.connect-section .social-link a i img {
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.news-letter .main-form .submit {
    background: #12441E;
    outline: none;
    border: 0;
    color: #E8E0CC ;
    transition: 0.5s;
}
/* ploorplan-page css */
.padding_section{
    padding: 40px 0;
}
.flor_cust .select-modal-slider .floor_img img {
    height: 450px !important;
    object-fit: cover;
}
.graph-map .img-slider:after {
    background: unset;
}
.graph-map .text-inner .scroll_text {
    color: #444444 ;
    margin-top: 10px;
}
.flor_cust .graph-map .text-inner table {
    width: 100%;
    line-height: 25px;
    border-top: 1px solid #444 !important;
    padding: 10px 0 15px;
    border-bottom: 2px solid #444 !important;
    display: block;
    border: 0;
}
.padding-top td {
    color: #E8E0CC;
}
.graph-map .text-inner table td {
    color: #444 !important;
    font-size: 14px;
    width: 50%;
    border: 0;
    padding: 0;
}
.graph-map .text-inner table .classic-button {
    display: flex;
    align-items: center;
    grid-column-gap: 20px;
    column-gap: 16px;
    height: 30px;
    margin-top: 5px;
}
.graph-map .text-inner table .classic-button button {
    border: 1px solid #E8E0CC;
    border: 1px solid #E8E0CC;
    background: transparent;
    color: #E8E0CC;
    padding: 0px 15px;
    height: 100%;
    line-height: 24px;
}
.flor_cust .graph-map .slider-text {
    width: calc(50% - 30px);
}
.flor_cust .graph-map .slider-text table {
    max-width: 360px;
}
.flor_cust .graph-map .slider-text strong {
    color: #E8E0CC;
}

.graph-map .text-inner .value {
    color: #E8E0CC ;
    border: 0;
    padding: 10px 0;
}
 /* overview css */
 .slider-text .text-inner .score_mit {
    margin:16px 0;
}
.slider-text .text-inner .score_mit p{
    margin: 0;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    color: #444444;
}
.select_mod-page .description{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.slider-text .text-inner table {
    width: 100%;
    line-height: 35px;
    border-top: 2px solid  #E8E0CC ;
    padding: 10px 0;
    border-bottom: 2px solid  #E8E0CC ;
    display: block;
}
.slider-text .text-inner table td {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}
.select-modal-slider .summery-main h2 {
    margin-left: 0;
}
.upgra_prem .right-box h2 {
    padding-left: 15px !important;
}
.premium-title{
    display: flex;
    justify-content: space-between;
}
.select-modal-slider .summery-main .summary-data {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    border-bottom: 2px solid #FFFFFF ;
    padding: 5px 0;
}
.select-modal-slider .summery-main .summary-data .data h5,
.select-modal-slider .summery-main .summary-data .data h6,
.select-modal-slider .summery-main .summary-data .data p {
    font-size: 14px;
}
.select-modal-slider .summery-main .summary-data .price {
    display: flex;
    align-items: center;
    width: 50%;
    color: #444444;
    justify-content: flex-end;
}
.select-modal-slider .delivery {
    background: #444444;
    padding: 40px 15px;
    max-width: 480px;
    border-radius: 5px;
}
.select-modal-slider .delivery .form-select,
 .select-modal-slider .delivery textarea {
    height: 50px;
    margin-bottom: 20px;
    border: 0;
    border-radius: 0;
    width: 100%;
}
.select-modal-slider .delivery textarea {
    height: 150px;
    padding: 10px;
    outline: none;  
}
.no_address a.custem-btn.p_btn {
    max-width: 100%;
}
.no_address .custem-btn, .right-box .price-value .custem-btn,
 .confirm-page .price .custem-btn {
    background: transparent;
    border-color: #ffffff !important;
    color: #ffffff !important;
    border-radius: 100px !important;
    border-width: 2px !important;
    box-sizing: border-box;
}
.no_address .custem-btn:hover, .right-box .price-value .custem-btn:hover,
 .confirm-page .price .custem-btn:hover{
    border-width: 2px !important;
    border-color: transparent !important;
    color: #12441E !important;
    background-color: #FFFFFF;
}
.no_address .custem-btn::after {
    display: none;
}
.back_con_btn {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 48px;
    margin: 0 0px;
    background: #fff;
    padding:30px 0;
    margin-bottom: 20px;
}
.delivery .select-modal-slider .back_con_btn {
    grid-template-columns: auto;
    column-gap: 0;margin-bottom: 0;
}
.delivery .select-modal-slider .back_con_btn .custem-btn {
    max-width: 100%;
}
.select-modal-slider .delivery .input input {
    margin-bottom: 30px;
}
.select-modal-slider .delivery .form-select:focus {
    box-shadow: none;
}
.select-modal-slider .delivery .price span, .right-box .price span {
    color: #fff;
    padding: 15px 0;
    display: inline-block;
}
.select-modal-slider .delivery .price strong, .right-box .price strong {
    display: block;
    color: #E8E0CC ;
    font-size: 36px;
    font-weight: 700;
}
.summary-data .data p {
    margin-bottom: 0;
}
.summary-data  .price strong {
    display: block;
    font-size: 20px;
    font-weight: 400;
}
.summary-data .price span {
    text-align: right;
    margin-right: 15px;
    font-size: 14px;
    width: 70%;
}
.summary-data .price .custem-btn {
    width: 30%;
    background: transparent;
    color:#444444 !important;
    padding: 12px !important;
}
.select-modal-slider .summery-main .summary-data .data {
    width: 50%;
    color: #444444;
}
.col-md-space-class .balls img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
}
/* kitchen css */
.kitchen-page .img-slider img, .bathroom-page .select-modal-slider .img-slider img {
    height: 419px;
    object-fit: cover;
}
.description {
    display: none;
}
.fix_height .select-modal-slider {
    margin-bottom: 0;
}
.api_data {
    width: 100%;
}
.api_data .meserment {
    border-bottom: 2px solid;
    margin-bottom: 5px;
}
.right-box {
    width: 100%;
    background: #000000a6;
    padding: 0 15px;
}
.right-box h2 {
    max-width: unset !important;
    margin: 0 -15px !important;
    padding-left: 0 !important;
}
.right-box .summary-data {
    /* padding-top: 16px !important;
    padding-bottom: 10px !important; */
    color: #FFFFFF ;
}
.right-box .price-value {
    margin-bottom: 30px;
}
.right-box .price-value .custem-btn {
    max-width: 100%;
}
.design-change-kitchen-page .interior div {
    flex: 0 200px;
}
.design-change-kitchen-page .interior div img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
}
.design-change-kitchen-page .interior .is_active {
    position: relative;
}
.design-change-kitchen-page .interior .is_active::before {
    top: 90px !important;
    left: 90px;
}

.custem-btn{
    text-align: center;
    background: rgba(18, 68, 30, 1);
    color: #E8E0CC ;
    padding: 15px 35px ;
    display: inline-block;
    width: 100%;
    border: 1px solid rgba(18, 68, 30, 1);
    border-radius: 0 !important ;
    text-transform: uppercase;
    text-decoration: none;
    transition: 1s;
    position: relative;
    max-width: 323px;
    font-size: 14px;
    font-weight: 500;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.custem-btn:hover{
    color: rgba(18, 68, 30, 1) ;
    background: #FFFFFF ;
    border: 1px solid rgba(18, 68, 30, 1);
}
.custem-btn::after,  
.back::before{
    content: '\f054';
    position: absolute;
    padding: 1px 6px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    font-size: 14px;
    color: rgba(18, 68, 30, 1);
    font-family: 'FontAwesome';
    font-weight: 400;
    transition: 1s;
    display: inline-block;
    margin-left: 10px;
}
.back::after{
    display: none;
}
.confirm-page .price .custem-btn {
    max-width: 100% !important;
}
.select-modal-slider .img-slider .slide img {
    height: 381px;
    width: 100%;
    object-fit: cover;
}
.custem-btn:hover::after, .back:hover:before {
    background: rgba(18, 68, 30, 1);
    color: #FFFFFF ;
}
.nxt_back_btn {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 483px;
    column-gap: 30px;
}
.back::before {
    content: "\f053";
    margin-left: -30px;
    padding: 1px 3px;
}
.summary-data .price .custem-btn::after, .confirm-page .price .custem-btn::after {
    display: none;
}
.select-modal-slider .delivery .price .custem-btn::after {
    display: none;
}
.floorplan-slider .carousel-item img {
    height: 600px;
}
.api_data p {
    text-transform: capitalize;
    font-weight: 400;
}
.api_data .total-pr  strong {
    font-size: 36px;
    display: block;
    color: #E8E0CC ;
}
.api_data .total-pr p {
    text-align: right;
}
.floor-slider {
    max-height: 400px;
}
.floor-slider .carousel.slide .carousel-inner img {
    height: 400px;
    object-fit: cover;
}
.carousel-control-next, .carousel-control-prev{
    z-index: 9;
}
.title_img img {
    width: 100%;
    padding: 0;
}
.title_img .title {
    margin-left: 0 !important;
}
.kitchen-page .select-modal-slider .img-slider .carousel a,  
.bathroom-page .select-modal-slider .img-slider .carousel a{
    display: none;
}
 .no_button {
    margin-top: 24px;
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: 0 !important;
}
.confirm-page .no_button {
    margin-bottom: 0;
}
.no_button  a.custem-btn.p_btn2{
    margin-right: 30px;
}
 .img-slider {
    background: #fff;
}
.design-change-kitchen-page .is_active div li,
.bathroom-page .is_active div li,  .upgra_prem .select_img li {
    font-size: 14px;
    line-height: 26px;
    position: relative;
    padding-left: 15px;
    list-style: none;
}
.design-change-kitchen-page .is_active div li::before,
.bathroom-page .is_active div li::before,  .upgra_prem .select_img li::before{
    position: absolute;
    content: '';
    left: 0;
    top: 9px;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background: black;
}
.design-change-kitchen-page .is_active .title,
.bathroom-page .title_img .col-md-space-class .title, .upgra_prem .title{
    margin-bottom: 20px !important;
    clip-path: polygon(0% 0%, 75% 0%, 95% 1%, 85% 100%, 0% 100%) !important;
    padding-left: 15px !important;
 }
 .delivery .no_address .locationbtn {
    max-width: 100%;
    outline: 0;
    margin-bottom: 15px;
}
/* media query :start */
@media screen and (min-width:1099px) {
    .no_button .custem-btn{
        max-width: 355px !important;
    }
}
@media screen and (max-width:1279px) and (min-width:1024px) {
    .slider-text .text-inner h2 {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .slider-text .text-inner .value {
        margin: 10px 0;
        padding: 5px 0;
    }
    .price_title strong {
        font-size: 30px;
    }
    .kitchen_value p strong {
        font-size: 30px;
    }
    .summary-data .price strong {
        font-size: 30px;
    }
}
@media screen and (max-width:1023px) and (min-width:992px) {
    .slider-text .text-inner h2 {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .slider-text .text-inner .value {
        margin: 10px 0;
        padding: 5px 0;
    }
    .price_title strong {
        font-size: 30px;
    }
    .kitchen_value p strong {
        font-size: 30px;
    }
    .summary-data .price strong {
        font-size: 30px;
    }
    .no_button .custem-btn{
        max-width: 310px !important;
    }
}
@media screen and (max-width:991px) and (min-width:768px) {
    .select-modal-slider .slider-text {
        margin-left: 15px;
    }
    .slider-text .text-inner h2, .title,
    .select-modal-slider .summery-main h2, .design-change-kitchen-page h2{
        font-size: 25px;
        padding-left: 15px;
        margin-left: -15px;
    }
    .select-modal-slider .img-slider .slide img{
        height: auto !important;
    }
    .summary-data .price strong{
        font-size: 30px;
    }
    .nav-war-main-wrap .nav-link span {
        font-size: 12px;
    }
    .select_mod-page .description {
        text-overflow: unset;
        white-space: unset;
    }
    .flor_cust .graph-map .slider-text table {
        max-width: 100%;
    }
    .slider-text .text-inner .score_mit {
      margin: 5px 0;
    }
    .custem-btn{
        padding: 10px 35px !important;
    }
    .no_button .custem-btn{
        max-width: 240px;
    }
    .slider-text .text-inner .value strong{
        font-size: 25px;
    }
    .slider-text .text-inner .value {
        padding-bottom: 10px;
    }
    .graph-map .text-inner p {
        padding-left: 15px;
    }
    .flor_cust .graph-map .slider-text table {
        padding-left: 15px;    
    }
    .flor_cust .graph-map .slider-text table .value {
        padding-left: 0;
    }
    .flor_cust .graph-map .slider-text .value p {
        padding-left: 0;
    }
    .price_title strong {
        font-size: 25px;
    }
    .kitchen_value p strong {
        font-size: 22px;
    }
    .summary-data .price strong {
        font-size: 22px;
    }
    .summary-data .price span{
        font-size: 12px;
    }
    .summary-data .price .custem-btn{
        padding: 10px !important;
    }
    .right-box .price-value .custem-btn{
        max-width: 323px !important;
    }
    .no_button {
        margin-right: 0 !important;
    }
    .confirm-page .price .custem-btn{
        font-size: 12px;
        padding: 15px 20px !important;
    }
}
@media screen and (max-width:767px) {
    .select-modal-slider .slider-text {
        margin-left: 0;
        width: 100%;
    }
    .slider-text .text-inner .value strong {
        font-size: 22px;
    }
    .our-partenr .logo-gallery div {
        width: 100%;
    }
    .slider-text .text-inner h2, .title,
    .select-modal-slider .summery-main h2, .design-change-kitchen-page h2{
        font-size: 22px;
        margin-left: 0;
        padding-left: 15px;
    }
    .summary-data.no_border{
        margin-top: 0;
    }
    .summary-data .price span{
        padding-right: 0;
        font-size: 10px;
    }
    .select_mod-page .select-modal-slider .carousel.slide img{
        height: auto !important;
    }
    .floorplan-slider .carousel-item img, .kitchen-page .img-slider img {
        height: auto !important;
    }
    .floor-slider .carousel.slide .carousel-inner img,
    .bathroom-page .select-modal-slider .img-slider img{
        height: auto !important;
    }
    .select_mod-page .description {
        text-overflow: unset;
        white-space: unset;
    }
    .flor_cust .select-modal-slider .floor_img img {
        height: auto !important;
    }
    .flor_cust .graph-map .img-slider img {
        height: auto;
    }
    .flor_cust .graph-map .slider-text {
        width: 100%;
    }
    .custem-btn {
        max-width: 100% !important ;
    }
    .no_button .custem-btn{
        margin-bottom: 15px;
    }
    /* date 22-1-22 satuarday*/
    .fix_height img, .fix_height .title_img .is_active {
        height: auto;
    }
    .bathroom-page .fix_height .is_active img {
        height: auto;
    }
    .slider-text .text-inner .score_mit {
        padding-left: 15px;
    }
    .slider-text .text-inner .value {
        padding-left: 15px;
    }
    .graph-map .text-inner p {
        padding-left: 15px;
    }
    .flor_cust .graph-map .slider-text table {
        padding-left: 15px;
        max-width: 100%;
    }
    /* date 22-1-22 satuarday*/
    .flor_cust .graph-map .slider-text table .value {
        padding-left: 0;
    }
    .flor_cust .graph-map .slider-text .value p {
        padding-left: 0;
    }
    .price_title strong {
        font-size: 22px;
    }
    .kitchen_value p strong {
        font-size: 22px;
    }
    .summary-data .price strong {
        font-size: 20px;
    }
    .confirm-page .price .custem-btn{
        padding: 15px 7px !important;
    }
    .summary-data .price .custem-btn{
        padding: 10px !important;
        font-size: 14px;
    }
    .no_button{
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    flex-wrap: wrap;
    }
    .confirm-page .delivery{
        max-width: 100%;
    }
    .design-change-kitchen-page .is_active .title, 
    .bathroom-page .title_img .col-md-space-class .title,
    .upgra_prem .title{
        padding-left: 15px !important;
        /* padding-right: 10px; */
        /* width: 100%; */
     }
}



body{
    font-family: 'Roboto' ;
}
:root {
    scroll-behavior: smooth;
    scroll-margin: 0;
    overflow-y: scroll;
}
.container{
    max-width: 1130px !important;
}
.nav-war-main-wrap .active span{
     font-weight: 700 ;
     position: relative;
 }
 .nav-war-main-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-bottom: 2px solid #12441E;
}
.nav-war-main-wrap a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    color: #444444;
    width: 170px;
}
.nav-war-main-wrap a span {
    display: inline-block;
    margin: auto;
}
.nav-war-main-wrap a i {
    width: 112%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    height: 80px;
    position: relative;
    background: #12441E;
    clip-path:polygon(75% 0%, 89% 50%, 75% 100%, 0% 100%, 10% 50%, 0% 0%);
}
.nav-war-main-wrap .active span:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 2px;
    width: 100%;
    background: #12441E;
}
.nav-war-main-wrap a:hover{
    color: #12441E;
}
.nav-war-main-wrap .active span:after {
    content: "";
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-top: 10px solid #12441E;
    display: inline-block;
    height: 0;
    width: 0;
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: rotate(
    360deg) translate(-50%, -50%);
}
.nav-war-main-wrap .active i{
    background-color: #DDEEE3;
    color: #FFFFFF;
    background-image: url(../src/Component/pages/imges/tick-arrow.png);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position-x: 55px;
    height: 100%;
    padding: 40px;
}
.nav-war-main-wrap .active .icon img{
    filter: invert(1);
}
.nav-war-main-wrap a .icon img {
    width: 100%;
}
/* confirm css */
.confirm-page .delivery {
    max-width: 500px;
    margin: auto;
    background: #444444;
    padding: 15px;
}
.validation{
    color:red
}
.pdfkiltm{
    display: flex;
    justify-content: space-between;
    border-bottom:2px ;
    border-color: #000;
}

.design-change-kitchen-page, .bathroom-page,
.upgra_prem, .delivery {
    margin-top: 20px;
}
.confirm {
    margin: 44px 0;
}
.confirm .summery-main {
    width: 100%;
}
.confirm .select-modal-slider {
    align-items: flex-start;
}
.confirm-page .delivery textarea {
    width: 100%;
    margin: 0 0 20px 0;
    height: 200px;
    outline: none;
    padding: 15px;
    font-size: 14px;
    /* text-transform: capitalize; */
}
.confirm-page .delivery .input input {
    width: 100%;
    margin-bottom: 30px;
    height: 56px;
    outline: none;
    /* text-transform: lowercase; */
    padding: 15px;
    font-size: 14px;
}
.delivery .no_address a.custem-btn.p_btn {
    margin-bottom: 20px;
}
.delivery .no_address a.custem-btn.p_btn:last-child {
    margin-bottom: 0;
}
/* .confirm-page .delivery .custem-btn::before{
    display: none;
}  */
.upgra_prem {
    margin-bottom: 30px;
}
.delivery .select-modal-slider {
    align-items: flex-start;
    align-items: flex-start;
    max-width: 470px;
    margin: auto;
    width: 100%;
    display: unset;
}
.loadding{
    padding-top: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #12441E;
}
@media screen and (max-width:1279px) and (min-width:1024px) {
    .nav-war-main-wrap .active span:before{
        bottom: -3px;
    }
    .nav-war-main-wrap .active span:after{
        bottom: -17px;
    }
}
 @media screen and (max-width:1023px) and (min-width:992px) {
    .nav-war-main-wrap {
        justify-content: space-between;
    }
    .nav-war-main-wrap .active span:before{
        bottom: -3px;
    }
    .nav-war-main-wrap .active span:after{
        bottom: -17px;
    }
 }
@media screen and (max-width:991px) and (min-width:768px) {
    nav.margin-top.p-3 {
        width: 100%;
        overflow-x: auto;
    }
    .nav-war-main-wrap a .icon {
        width: 115%;
        height: 60px;
        padding: 16px;
    }
    .ppp{
        padding: 35px;
        background-position-x: 25px !important;
        background-size: 40px 40px !important    ;
    }
    .nav-war-main-wrap .active i{
        background-size: 30px 30px;
        background-position-x: 45px;
    }
    .right-box{
        padding: 0 15px !important;
    }
    .right-box {
        margin-left: -10px;
    }
    .right-box {
        width: 110% !important;
    }
}
@media screen and (max-width:767px) {
    .btn_kit_sect_back {
        order: 2;
    }
    .nav-war-main-wrap {
        width: 750px;
    }
    nav.margin-top {
        width: 100%;
        overflow-x: auto;
    }
    .nav-war-main-wrap .active i{
        background-size: 30px 30px;
        background-position-x: 35px;
    }
    .ppp{
        padding: 35px;
        background-position-x: 19px !important;
        background-size: 30px 30px !important    ;
    }
    .nav-war-main-wrap a .icon{
        width: 100%;
        height: 55px;
        padding: 16px;
    }
    .nxt_back_btn {
        max-width: 100% !important;
        grid-template-columns: auto !important;
    }
}
 
.text_p p {
    margin-bottom: 5px;
}
.kitchen_value p {
    margin-bottom: 0;
    text-align: right;
    font-size: 12px;
}
.kitchen_value p strong {
    font-size: 28px;
    display: block;
    color: #fff;
}
.right-box .price-value .kitchen_value p {
    text-align: left;
    color: #fff;
}
.summary-data.no_border {
    border: 0!important;
    margin-top: 15px;
}
.adding_price {
    height: 220px;
    display: flex;
    padding: 15px 0;
    align-items: end;
}
.price_title {
    display: flex;
    align-items: center;
    /* padding: 15px; */
    justify-content: space-between;
}
.price_title strong {
    font-size: 28px;
    padding-right: 5px;
}

.price_title span {
    display: block;
    font-size: 12px;
    text-align: right;
}
.price_title p {
    margin: 0;
    font-size: 25px;
    padding-left: 15px;
}
.price_title1 {
    display: flex;
    align-items: center;
    /* padding: 15px; */
    justify-content: space-between;
}
.price_title1 p {
    margin: 0;
    font-size: 25px;
}
.premium_dis{
    padding-top: 15px ;
    font-size: 14px;
    /* margin: 0px; */
}
.kitchen_dis {
    color: #fff;
    border-bottom:2px  solid #fff;
    padding: 15px 0;
    font-size: 14px;
}
.kitchen_space {
    margin-bottom: 99px;
}
.upgra_prem .select-modal-slider .space_pre {
    align-items: flex-start;
    padding-top: 10px;
    color: #E8E0CC;
     
}

/* premraj */
.data h5 {
    font-weight: 600;
    font-size: 18px !important;
}
.flor_cust .graph-map .img-slider {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* navigation.............  */
.nav-war-main-wrap a i:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../src/Component/pages/imges/tick-arrow.png);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position-x: 55px;
    width: 100%;
    height: 100%;
    /* opacity: 0; */
    display: none;
}
.ppp{
    display: none;
    background-image: url(../src/Component/pages/imges/tick-arrow.png);
    background-position: center;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position-x: 55px;
    width: 100%;
    height: 100%;
}
.BORDER{
    border:2px solid #000;
}
.select-modal-slider .delivery .form-select {
    background-image: unset;
}
.swal2-title {
    color: #444444;
}
.swal2-styled.swal2-confirm {
    background-color: #0f5132 !important;
}

p.error {
    color: #ff7474;
}